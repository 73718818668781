import { useRouter } from 'next/router';
import styles from './section.module.scss';

export default function Section({ children, className = '', ...res }) {
    const router = useRouter();
    const style = router.pathname.includes('business') 
        ? { backgroundColor: '#F7FFF7' } 
        : {};

    return (
        <section 
            className={`${styles.section}${className && ' ' + className}`} 
            style={style}
            {...res}
        >
            {children}
        </section>
    );
}
