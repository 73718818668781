import Button from "../../../Misc/button/Button";
import LayoutFillImg from "../../../Misc/LayoutFillImg/LayoutFillImg";
import Section from "../../../Misc/section/Section";

import styles from "./landscaping.module.scss";
const Landscaping = () => {
  return (
    <Section className={styles.LandscapingmainConatiner}>
      <div className={styles.lanscContainer}>
        <div className={styles.lanscSubContainer}>
          <h2 className={styles.lanscHeading}>AI-powered outdoor &</h2>
          <h4 className={styles.lanscsubHeading}>landscaping design</h4>
          <p className={styles.lanscDisc}>
            By selecting the area you would like to redesign and selecting your
            desired product, our AI models automatically re-design your exterior
            space, taking into account your existing layout. You can also add water
            features like swimming pools & fountains, outdoor
            furniture/appliances, BBQ islands and lighting features!
          </p>
          <div className={styles.lansc_x222responsive}>
            <Button variant="primary" link="/business">
              Get Contractor Access
            </Button>
            <Button variant="secondary" link="/free/free-editor">
              Try For Free
            </Button>
          </div>
        </div>

        <div className={styles.lanscBoxImage}>
          <div className={styles.lansc_x1}>
            <div className={styles.lansc_x11}>
              <LayoutFillImg
                src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1737568657/userGeneratedImages/lwyhm2e6jdyphanm4ukg.png"
                alt="landscaping"
              />
            </div>

            <div className={styles.lansc_x12}>
              <div className={styles.lansc_x121}>
                <LayoutFillImg
                  src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736819590/userGeneratedImages/lek5gcwubi4vnq9ahdwh.png"
                  alt="landscipping"
                />
              </div>
              <div className={styles.lansc_x122}>
                <LayoutFillImg
                  src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736817376/userGeneratedImages/xewk0jweyw4l4xqubhtl.png"
                  alt="landscipping"
                />
              </div>
            </div>

            <div className={styles.lansc_x13}>
              <LayoutFillImg
                src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736816547/userGeneratedImages/hbgwatiyy5w6y1bimbbq.png"
                alt="landscipping"
              />
            </div>
          </div>

          <div className={styles.lansc_x2}>
            <div className={styles.lansc_x21}>
              <div className={styles.lansc_x211}>
                <LayoutFillImg
                  src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1719046976/Biz%20landing%20assets/WhatsApp_Image_2023-08-18_at_13.43.10_80ea62c8_srjif9.jpg"
                  alt="landscipping"
                />
              </div>

              <div className={styles.lansc_x212}>
                <LayoutFillImg
                  src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1737057218/userGeneratedImages/ibtsufnpq9epwhlrm1n9.png"
                  alt="landscipping"
                />
              </div>
            </div>

            <div className={styles.lansc_x22}>
              <div className={styles.lansc_x221}>
                <LayoutFillImg
                  src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1737055336/userGeneratedImages/zcjzsags8td56enapcrk.png"
                  alt="landscipping"
                />
              </div>
              <div className={styles.lansc_x222}>
                <Button variant="primary" link="/business/get-access">
                  Get Contractor Access
                </Button>
                <Button variant="secondary" link="/free/free-editor">
                  Try For Free
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.LandscapingCorner}>
        <LayoutFillImg
          src="/assets/landscappingcorner.svg"
          alt="landscapping corner"
        />
      </div>
    </Section>
  );
};

export default Landscaping;
